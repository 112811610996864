.dashboard {
    display: flex;
    height: 100%;
  }
  
  .sidebar {
    width: 250px;
    height : 600px;
    background-color: #2c3e50;
    color: #ecf0f1;
    padding: 15px;
  }
  
  .sidebar h2 {
    text-align: center;
  }
  
  .sidebar ul {
    list-style-type: none;
    padding: 0;
  }
  
  .sidebar ul li {
    margin: 15px 0;
  }
  
  .sidebar ul li a {
    color: #ecf0f1;
    text-decoration: none;
  }
  
  .sidebar ul li a:hover {
    text-decoration: underline;
  }
  
  .main-content {
    flex-grow: 1;
    padding: 20px;
  }
  
  .main-content h1 {
    margin-top: 0;
  }
  